import { instance } from "./settings";

export const vinCheckAPI = {
  checkVin: async (vin) => {
    try {
      const response = await instance.get(`vin/${vin}`);

      return response.data;
    } catch (e) {
      return e;
    }
  },

  submitVin: async (data) => {
    try {
      const response = await instance.post(`vin`, data);

      return response;
    } catch (e) {
      return e;
    }
  },

  setOrder: async (data) => {
    try {
      const response = await instance.post("order", data);
      return response.data;
    } catch (e) {
      return e;
    }
  },

  checkPromocodeAndEmail: async ({ promoCode, email }) => {
    const promocodeString = promoCode ? `&code=${promoCode}` : "";
    try {
      const response = await instance.get(
        `promocode/check?email=${email}${promocodeString}`
      );

      return response.data;
    } catch (e) {
      return e;
    }
  },
};
