import axios from "axios";
import { isServer } from "../utils";

export const instance = axios.create({
  baseURL: `${process.env.API_URL}/api/`,
  headers: {
    "Content-Type": "application/json",
    "user-id": isServer ? null : localStorage.getItem("user-id"),
  },
});
